import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: #fff;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: #28293cd9;
  width: 100%;
  border-radius: 30px;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const PanelInContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: transparent;
  width: 100%;
  border-radius: 30px;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

// Used for providing a wrapper around a component
export const MintPanel = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: #28293cd9;
  width: 800px;
  border-radius: 30px;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  
  @media (max-width: 580px) {
    width: auto;
  }
`;

export const TextTitle = styled.p`
  font-family: 'Upheaval';
  color: var(--primary-text);
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 8px;
  line-height: 1.6;
`;

export const TextSub = styled.p`
  font-family: 'Upheaval';
  color: var(--primary-text);
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 8px;
  line-height: 1.6;
  @media (max-width: 580px) {
    letter-spacing: 0px;
  }
`;

export const TextSubTitle = styled.p`
  font-family: 'Renomono';
  color: var(--primary-text);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 30px;
  line-height: 1.6;
`;

export const TextAm = styled.p`
color: var(--primary-text);
font-size: 30px;
line-height: 1.6;
@media (max-width: 580px) {
  color: var(--primary-text);
  font-size: 30px;
  line-height: 1.6;
}

`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledHR = styled.hr`
  border: 2px solid #8C7CAA;  
  background-color: #8C7CAA;
  border-radius: 2px;
  width: 450px;
  @media (max-width: 580px) {
    width: 350px;
  }
`;

